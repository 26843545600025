import { navigate } from 'gatsby'
import { FormEvent, useState } from 'react'

import Button from '~/components/buttons/Button'
import { IS_BROWSER } from '~/constants/browser'

import * as containerStyles from './SearchInput.module.scss'

const SearchInput = () => {
  const { search, pathname } = IS_BROWSER
    ? window.location
    : { search: '', pathname: '' }

  const searchParams = new URLSearchParams(search)
  const query = searchParams.get('s')
  const [searchQuery, setSearchQuery] = useState(query || '')

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!searchQuery) return

    if (pathname !== '/blog/search/') {
      navigate(`/blog/search/?s=${searchQuery}`)
    } else if (pathname === '/blog/search/' && query !== searchQuery) {
      navigate(`${pathname}?s=${searchQuery}`)
    }
  }

  return (
    <div className={containerStyles.search}>
      <form
        onSubmit={(e) => onSubmitForm(e)}
        className={containerStyles.search__form}
      >
        <input
          name="s"
          type="text"
          id="header-search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={containerStyles.search__input}
          placeholder="Search blog posts"
        />
        <Button
          type="submit"
          name="Search blog posts"
          customClassName={containerStyles.search__btn}
        >
          <span className={containerStyles.search__arrow} />
        </Button>
      </form>
    </div>
  )
}

export default SearchInput
