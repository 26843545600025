import { navigate } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { useEffect, useRef, useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import MainLayout from '~/components/layouts/MainLayout'
import { Pagination } from '~/components/shared/Pagination'
import SEO from '~/components/shared/Seo'
import { SearchProps } from '~/templates/blog/Search'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import BlogPostList from '~/views/Blog/components/BlogPostList'
import NavigationCategory from '~/views/Blog/components/NavigationCategory'
import NavigationTag from '~/views/Blog/components/NavigationTag'
import SearchNotFound from '~/views/Blog/views/Search/components/SearchNotFound'

import { useParams } from '@reach/router'

import * as containerStyles from '../../Blog.module.scss'

const SearchView = ({ data }: SearchProps) => {
  const { og_image } = getPageMeta(data.strapiPage)
  const params = useParams()

  const {
    localSearchPages: { index, store },
  } = data

  const searchText = params?.s || ''
  const currentPage = params?.p || 1
  const limitResultOnPage = 10

  const searchResult = useFlexSearch(searchText, index, store).sort(
    (a, b) => Date.parse(b.modified_date) - Date.parse(a.modified_date),
  )

  const pages = Math.ceil(searchResult.length / 10)
  const pageData = searchResult.slice(
    (currentPage - 1) * limitResultOnPage,
    currentPage * limitResultOnPage,
  )

  const transformUrl = ({ url, page }) => {
    const newQuery = new URLSearchParams(searchText)

    newQuery.set('p', page)

    return page === 1 ? url : `${url}&p=${newQuery.get('p')}`
  }

  const seoTitle =
    currentPage !== 1
      ? `Search on blog - Page ${currentPage}`
      : 'Search on blog'

  const seoDescription =
    currentPage !== 1
      ? `Search results on Codica Blog - Page ${currentPage}`
      : 'Search results on Codica Blog'

  const [badRequestPage, setBadRequestPage] = useState(false)

  const isInitialized = useRef<boolean>(true)

  useEffect(() => {
    if (isInitialized.current && pageData.length === 0) {
      navigate(`/blog/search/?s=${searchText}`, { replace: true })
      setBadRequestPage(true)
    } else {
      isInitialized.current = true
      setBadRequestPage(false)
    }
  }, [pageData.length, isInitialized.current])

  return (
    <MainLayout isStaticChat scrollTop>
      <SEO
        title={seoTitle}
        description={seoDescription}
        urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
        url={`https://www.codica.com/blog/search/?s=${searchText}`}
        meta={[
          {
            name: 'robots',
            content: badRequestPage
              ? 'noindex, nofollow'
              : 'noindex, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
          },
        ]}
      />

      {badRequestPage ? (
        <SearchNotFound />
      ) : (
        <div className={containerStyles.blog}>
          <div className="container container-md">
            <div className="row">
              <div className="col-12">
                <div className={containerStyles.blog__breadcrumbsWrapper}>
                  <Breadcrumbs
                    secondLevel="Blog"
                    secondLevelPath="/blog/"
                    current={`${searchText}`}
                    currentPath={`https://www.codica.com/blog/search/?s=${searchText}`}
                  />
                </div>
                <h1 className={containerStyles.blog__title}>Search results</h1>
              </div>

              <NavigationCategory data={data} />
              <NavigationTag data={data} />
              <BlogPostList data={pageData} withHeading onlyPosts />

              <div className="col-12">
                <div className={containerStyles.blog__paginationWrapper}>
                  {searchResult.length > 10 && (
                    <Pagination
                      countPages={pages}
                      currentPage={currentPage}
                      url={`/blog/search/?s=${searchText}`}
                      transformUrl={transformUrl}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  )
}

export default SearchView
