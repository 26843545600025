import { graphql } from 'gatsby'

import {
  AllStrapiCategory,
  AllStrapiTag,
  QueryResult,
  StrapiPage,
} from '~/types/graphql'
import SearchView from '~/views/Blog/views/Search'

export type SearchProps = QueryResult<
  StrapiPage &
    AllStrapiCategory &
    AllStrapiTag & { localSearchPages: { index: any; store: any } }
>

const Search = ({ data }: SearchProps) => <SearchView data={data} />

export default Search

export const pageQuery = graphql`
  query {
    strapiPage(page_name: { eq: "Blog" }) {
      ...MetaTagInformationPages
    }

    localSearchPages {
      index
      store
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          name
          nav_name
          id
        }
      }
    }

    allStrapiTag(sort: { number_tag: ASC }) {
      edges {
        node {
          name
          url
          articles {
            active
          }
        }
      }
    }
  }
`
