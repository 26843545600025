import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import notFoundIcon from '~/assets/images/icons/icon-star-red.svg?file'
import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SocialBlock from '~/components/blocks/SocialBlock'
import SearchInput from '~/components/shared/SearchInput'
import { AllStrapiArticle, PhotosQuery } from '~/types/graphql'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './SearchNotFound.module.scss'

const SearchNotFound = () => {
  const query = useStaticQuery<AllStrapiArticle & PhotosQuery>(graphql`
    query ($published: [Boolean]) {
      allStrapiArticle(
        limit: 3
        sort: { createdAt: DESC }
        filter: { active: { eq: true }, published: { in: $published } }
      ) {
        edges {
          node {
            id
            url
            preview_image_alt
            category {
              name
            }
            preview_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
                }
              }
            }
            title
            meta_description
            modified_date
            body {
              data {
                childMarkdownRemark {
                  timeToRead
                }
              }
            }
          }
        }
      }

      searchNotFoundImage: file(
        relativePath: { eq: "not-found/not-found-blog.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const searchNotFoundImage = getImage(
    fileToImageLikeData(query.searchNotFoundImage),
  )

  return (
    <div className={containerStyles.searchNotFound}>
      <div className="container container-md">
        <div className={cx(containerStyles.searchNotFound__row, 'row')}>
          <div className="col-12 col-md-7">
            <div className={containerStyles.searchNotFound__wrapper}>
              <h1 className={containerStyles.searchNotFound__title}>
                No results found for
              </h1>
              <SearchInput />
              <div className={containerStyles.searchNotFound__descrWrapp}>
                <img
                  src={notFoundIcon}
                  className={containerStyles.searchNotFound__icon}
                  alt="Not Found page icon | Codica"
                  title="Not Found page icon"
                  width="32px"
                  height="34px"
                  loading="lazy"
                />
                <div className={containerStyles.searchNotFound__descrItem}>
                  You can ensure words are spelled correctly, try rephrasing
                  keywords or using synonyms, try more general keywords.
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <GatsbyImage
              image={searchNotFoundImage as IGatsbyImageData}
              className={containerStyles.searchNotFound__image}
              alt="Not Found page at Codica"
              title="Oops, page not found"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className={containerStyles.searchNotFound__recentPosts}>
        <HeadSection
          position="left"
          title="In the meantime, take a look at our most recent articles:"
        />
        <RecentArticles data={query} buttonText="View all blog posts" />
      </div>
      <SocialBlock />
    </div>
  )
}
export default SearchNotFound
